<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="box">


		<div class="mi-orderlist">
			<div class="container ">
				<div class="row" style="display: flex;">
					<div class="span4" ref="J_siteUserMenu">
						<div class="user-address-menu" id="J_userMenu">
							<div class="menu-box">
								<h3 class="title">
									订单中心
								</h3>
								<ul class="list">
									<li :class="[routerIndex==0?'menuActive':'']">
										<router-link to="/PersonalCenter/Order">我的订单</router-link>
									</li>
									<!-- <li class="">
										<router-link to="/PersonalCenter/Order">待支付</router-link>
									</li>
									<li class="">
										<router-link to="/PersonalCenter/Order">待发货</router-link>
									</li>
									<li class="">
										<router-link to="/PersonalCenter/Order">待收货</router-link>
									</li>
									<li class="">
										<router-link to="/PersonalCenter/Order">待评价</router-link>
									</li>
									<li class="">
										<router-link to="/PersonalCenter/Order">待售后</router-link>
									</li>
									<li class="">
										<router-link to="/PersonalCenter/Order">售后完成</router-link>
									</li> -->
								</ul>
							</div>
							<div class="menu-box">
								<h3 class="title">个人中心</h3>
								<ul class="list">
										<li :class="[routerIndex==1?'menuActive':'']">
										<router-link to="/PersonalCenter/Personal">个人信息</router-link>
									</li>
									<!-- <li><router-link to="/PersonalCenter/ChangePassword">修改密码</router-link></li> -->
									<!-- <li><router-link to="/PersonalCenter/ProductCollect">商品收藏</router-link></li>
									<li><router-link to="/PersonalCenter/ShopCollect">店铺收藏</router-link></li> -->
										<li :class="[routerIndex==2?'menuActive':'']">
										<router-link to="/PersonalCenter/Address">地址管理</router-link>
									</li>
									<li><router-link to="/PersonalCenter/PaymentSettings">支付设置</router-link></li>
								</ul>
							</div>
							<!-- <div class="menu-box">
								<h3 class="title">加入我们</h3>
								<ul class="list">
										<li :class="[routerIndex==3?'menuActive':'']">
										<router-link to="/Joinus/MerchantSettled">商家入驻</router-link>
									</li>
									
								</ul>
							</div> -->
						</div>

					</div>
					<div class="span16">
						<router-view></router-view>
					</div>

				</div>
			</div>
		</div>

		<!-- <div class="rt">
			
		</div> -->
	</div>
</template>
<script>
	export default {
		data() {
			return {
				orders: [], // 订单列表
				total: [], // 每个订单的商品数量及总价列表
				routerIndex: null,
				activeIndex:'',
			};
		},
		beforeUpdate() {
		 this.LeftIndex()
		},
		activated() {
			this.LeftIndex()
						
			// 获取订单数据
			// this.$axios
			// 	.post("/api/user/order/getOrder", {
			// 		user_id: this.$store.getters.getUser.user_id
			// 	})
			// 	.then(res => {
			// 		if (res.data.code === "001") {
			// 			this.orders = res.data.orders;
			// 		} else {
			// 			this.notifyError(res.data.msg);
			// 		}
			// 	})
			// 	.catch(err => {
			// 		return Promise.reject(err);
			// 	});
		},
		watch: {
			// 通过订单信息，计算出每个订单的商品数量及总价
			// orders: function(val) {
			// 	let total = [];
			// 	for (let i = 0; i < val.length; i++) {
			// 		const element = val[i];

			// 		let totalNum = 0;
			// 		let totalPrice = 0;
			// 		for (let j = 0; j < element.length; j++) {
			// 			const temp = element[j];
			// 			totalNum += temp.product_num;
			// 			totalPrice += temp.product_price * temp.product_num;
			// 		}
			// 		total.push({
			// 			totalNum,
			// 			totalPrice
			// 		});
			// 	}
			// 	this.total = total;
			// }
		},
		methods:{
			LeftIndex(){
				if (this.$route.path.indexOf('PersonalCenter/Order') == 1) {
					this.routerIndex = 0
				}
				else if (this.$route.path.indexOf('PersonalCenter/Personal') == 1) {
					this.routerIndex = 1
				}
				else if (this.$route.path.indexOf('PersonalCenter/Address') == 1) {
					this.routerIndex = 2
				}
				else if (this.$route.path.indexOf('Joinus/MerchantSettled') == 1) {
					this.routerIndex = 3
				}
				// else if (this.$route.path.indexOf('Joinus/ContactUs') == 1) {
				// 	this.routerIndex = 4
				// }

				else{
					this.routerIndex = null
				}
			},
		}
	};
</script>
<style scoped>
	.menuActive a{
		color:#603c1d !important;
	}
	.uc-main-box {
		padding: 36px 0;
	}

	.mi-orderlist {
		background: #f5f5f5;
		padding-bottom: 40px;
	}

	.container {
		width: 1226px;
		margin-right: auto;
		margin-left: auto;
	}

	.container:after,
	.container:before {
		content: " ";
		display: table;
	}

	.row {
		margin-left: -14px;
	}

	.row:after,
	.row:before {
		content: " ";
		display: table;
	}

	.span4 {
		width: 234px;
		flex-shrink: 0;
	}

	.user-address-menu {
		background: #fff;
		padding: 36px 0;
	}

	.user-address-menu .menu-box {
		margin: 0 48px 12px;
	}

	.user-address-menu h3 {
		font-size: 16px;
		font-weight: 400;
		line-height: 52px;
		color: #333;
		margin: 0;
		padding: 0;
	}

	.user-address-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.user-address-menu li {
		padding: 6px 0;
		font-size: 14px;
	}

	.user-address-menu .active a,
	.user-address-menu .active a:hover {
		color: #ff6700;
	}

	.user-address-menu li a {
		color: #757575;
	}

	.span16 {
		margin-left: 16px;
		width: 100%;
	}
</style>
